@media (min-width: 1024px) {
     .border-enterprise {
         border-right: .0625rem solid #e1e1e1 !important;
     }
 }

@media (max-width: 1023px) {
     .border-enterprise {
         border-bottom: .0625rem solid #e1e1e1 !important;
     }

     .enterprise-padding {
         padding-top: 10px;
     }
 }