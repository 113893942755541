/*code fix to override hr broken in latst version in dds*/
hr {
    border-top: 0.0625rem solid #e1e1e1 !important;
}

@media (min-width: 1024px) and (max-width: 1439px) {
     .dds__ml--lg-4 {
         margin-left: 30px !important;
     }
 }

.erroricon::before {
    padding-left: 4px;
    position: relative;
    left: -7px;
}


.supportservicebodydiv {
    padding-top: 14px !important;
}

@media (max-width: 1024px) {
    .divCoreTilesHeaders, .divTopicInterestTileHeaders {
        font-weight: 400;
        font-size: 1.25rem;
        line-height: 1.75rem;
    }
}

@media(min-width: 1920px) {

    .divTopicofInterest {
        padding-left: 68px;
        padding-right: 65px;
    }
}

@media(max-width: 767px) {
    .min-height-sr {
        min-height: 175px
    }

    .min-height-ss {
        min-height: 120px
    }
}

.min-height-ss {
    min-height: 83px;
}
