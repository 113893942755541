/*body {
  overflow-x: hidden; // For prototype usage only, to prevent horizontal scrolling.
}*/
/*$graphite: #707070;*/
.contact-slider {
  border: 0px solid #CD698B;
  border-top-left-radius: 0.40rem;
  border-bottom-left-radius: 0.40rem;
  display: flex;
  /*flex-direction: column;*/
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 50px;
  z-index: 1000;
  transform: translateX(100%) translateX(-51px);
  transition: transform 0.25s ease-in-out;
  width: 350px;
  /*.d-none {
        display: none !important;
    }*/ }

.contact-slider.contact-slider-lg {
  width: 350px; }

.contact-slider.open {
  transform: translateX(0.4%); }

.contact-slider .slider-row {
  display: flex; }

.contact-slider .slider-icon {
  background: #B7295A;
  border-bottom: 0px solid #CD698B;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  /*width: 3.5rem;
            height: 3.5rem;*/
  cursor: pointer; }

.contact-slider .slider-row.expandable .slider-icon {
  /*width: 3.5rem;*/
  width: 53.9px;
  height: auto; }

.contact-slider .slider-row.expandable .slider-body {
  background: #F4F4F4; }

.contact-slider .slider-row .slider-body {
  align-items: center;
  background: #fff;
  border-bottom: 0px solid rgba(0, 0, 0, 0.1);
  /*display: flex;*/
  flex-grow: 1;
  max-height: 250px;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0 1rem;
  position: relative; }

.contact-slider .slider-row .slider-body:hover, .contact-slider .slider-row .slider-body.slider-title-expanded {
  background: #F4F4F4; }

.contact-slider .slider-row .slider-body:hover .slider-body-title, .contact-slider .slider-row .slider-body.slider-title-expanded .slider-body-title {
  color: #004071; }

.contact-slider .slider-row .slider-body .slider-body-title {
  align-items: center;
  display: flex;
  justify-content: space-between;
  text-decoration: none;
  -webkit-appearance: none;
  width: 100%;
  padding-top: 0px; }

.contact-slider .slider-row .slider-body .slider-body-title:hover {
  text-decoration: underline; }

.contact-slider .slider-row .slider-body p.contact-subtitle {
  font-size: 0.74rem;
  color: #444444;
  margin-bottom: 0; }

.contact-slider .slider-row .slider-body .account-member {
  align-items: center;
  flex-grow: 1;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 0.75rem; }

.contact-slider .slider-row .slider-body .account-member:first-of-type {
  margin-top: 0.75rem; }

.contact-slider .slider-row .slider-body .account-member .member-img {
  align-items: center;
  border-radius: 50%;
  background: #A3A3A3;
  color: #ffffff;
  display: flex;
  height: 3rem;
  justify-content: center;
  margin-right: 0.5rem;
  width: 3rem; }

.contact-slider .slider-row .slider-body .account-member .member-img img {
  border-radius: 50%;
  height: 48px;
  width: 48px; }

.contact-slider .slider-row .slider-body .account-member .member-details {
  display: flex;
  flex-direction: column;
  flex-grow: 1; }

.contact-slider .slider-row .slider-body .account-member .member-details .name, .contact-slider .slider-row .slider-body .account-member .member-details .title {
  color: #444444;
  margin-bottom: 0; }

.contact-slider .slider-row .slider-body .account-member .member-details .title, .contact-slider .slider-row .slider-body .account-member .member-details a {
  font-size: 0.75rem; }

.contact-slider .slider-row .slider-body .notification {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  width: 100%; }

.contact-slider .slider-row .slider-body .notification:first-of-type {
  margin-top: 1rem; }

.contact-slider .slider-row .slider-body .notification p {
  margin-bottom: 0; }

.contact-slider .dti.dti-xl {
  /*height: 1.5rem;
        width: 1.5rem;*/
  width: 24px;
  height: 24px; }

.contact-slider .dti {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor; }

.contact-slider .text-white {
  color: #fff !important; }

.contact-slider .m-4 {
  margin: 16px !important; }

.contact-slider .align-items-start {
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important; }

.contact-slider .justify-content-center {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important; }

.contact-slider .flex-column {
  -webkit-box-orient: vertical !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important; }

.contact-slider .flex-column, .contact-slider .flex-row {
  -webkit-box-direction: normal !important; }

.contact-slider *, .contact-slider ::after, .contact-slider ::before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

.contact-slider .stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: transparent; }

@media (min-width: 768px) {
  .contact-slider .d-md-block {
    display: flex !important; } }

a.slider-icon-anchor {
  display: inline-block;
  padding-top: 0px;
  padding-bottom: 0px;
  text-decoration: none !important; }

a.slider-icon-anchor > object {
  pointer-events: none; }

.custom-switch {
  padding-left: 3rem !important; }

.contactus-slider-HES-Base {
  writing-mode: vertical-rl;
  -ms-writing-mode: bt-rl;
  color: white;
  /*padding: 19px 12px;*/
  transform: rotate(-180deg);
  width: 54px;
  font-size: 18px;
  font-family: 'Roboto-Medium'; }

.contactus-slider-HES-Base-APJCountry {
    writing-mode: vertical-rl;
    -ms-writing-mode: bt-rl;
    color: white;
    width: 54px;
    font-size: 18px;
    font-family: 'Roboto-Medium';
}
