#divPilotBanner .dds__message-bar--global__container {
    z-index: 100 !important;
}

.min-height-body {
    min-height: 400px;
    min-height: 55vh;
}

@media (max-width: 768px) {
     .windowshelpbtn {
         border: none !important;
     }

     .needhelpwindowsbtntext {
         font-size: 0.75rem !important;
     }

     .btnSignin, .btncreateacclink {
         font-size: 0.875rem !important;
         height: 40px !important;
     }
 }

.windowshelpbtn {
    line-height: 1.3rem !important;
}

.dds__accordion__item .dds__accordion > .dds__accordion__item::after {
    height: 0px !important;
}


@media screen and (min-width: 767.9px) {
    .herocontainerdiv {
        background-image: url('https://www.dellcdn.com/support/home/bundle/img/herosecnotauthlg.png');
        background-repeat: no-repeat;
        background-position: 96px !important;
        width: 100% !important;
        background-size: cover !important;
    }
}

@media screen and (min-width: 912px) {
    .herocontainerdiv {
        /*background-position: 200px !important;*/
        background-position: 100% !important;
        background-size: contain !important;
    }
}

@media screen and (min-width: 1070px) {
    .herocontainerdiv {
        background-position: 100% !important;
    }
}

.needhelpwindowsbtntext {
    padding-top: 2px !important;
}

.visitbusinesslink {
    font-weight: 500 !important;
}

.horizontallinesmherosection {
    width: 90% !important;
}

@media screen and (min-width: 767.9px) {
    .herosectionauthenterprise {
        background-image: url('https://www.dellcdn.com/support/home/bundle/img/herosectionenterprisemd.png');
        background-repeat: no-repeat;
        background-position: right;
        width: 100% !important;
        background-size: cover;
    }
 }

@media screen and (min-width: 1023.8px) {
    .herosectionauthenterprise {
        background-image: url('https://www.dellcdn.com/support/home/bundle/img/herosecauthenterpriselg.png');
        background-repeat: no-repeat;
        background-position: right;
        width: 100% !important;
        background-size: cover;
    }
 }

@media(max-width: 767.9px) {
     .welcomedescauthenterprise, .welcomebackmsg {
         color: var(--gray-900) !important;
     }

     .windowshelpbtn {
         border: 0 !important;
     }

     .needhelpwindowsbtntext {
         font-size: 0.75rem !important;
     }
 }

.windowshelpbtn {
    line-height: 1.3rem !important;
}

.img-fluid1enterprise-sm {
    max-width: 100% !important;
    height: auto !important;
}

.needhelpwindowsbtntext {
    padding-top: 2px !important;
}

@media screen and (min-width: 767.9px) {
    .herosectionauth {
        background-image: url('https://www.dellcdn.com/support/home/bundle/img/herosecauthsm.png');
        background-repeat: no-repeat;
        background-position: right;
        width: 100% !important;
        background-size: cover;
    }
 }

@media screen and (min-width: 1023.8px) {
    .herosectionauth {
        background-image: url('https://www.dellcdn.com/support/home/bundle/img/herosecauthlgmd.png');
        background-repeat: no-repeat;
        background-position: right;
        width: 100% !important;
        background-size: cover;
    }
 }

@media(max-width: 768px) {
     .windowshelpbtn {
         border: 0 !important;
     }

     .needhelpwindowsbtntext {
         font-size: 0.75rem !important;
     }
 }

.windowshelpbtn {
    line-height: 1.3rem !important;
}

.img-fluidconsumer-sm {
    max-width: 100% !important;
    height: auto !important;
}

.needhelpwindowsbtntext {
    padding-top: 2px !important;
}

a.btncreateacclink:not(:hover):not(:focus), a.windowshelpbtn:not(:hover):not(:focus) {
    background-color: var(--background-color--primary);
}

@media(min-width: 1650px) {
    .visitbusinessiconright {
        position: relative;
        top: -1px;
    }
}

@media(min-width: 1920px) {
    .divFixedContent {
        max-width: 1920px;
        margin-right: auto;
        margin-left: auto;
    }

   /* .divHeroEnterprise {
        background-image: url('https://www.dellcdn.com/support/home/bundle/img/herosecauthenterpriselg.png');
    }
    .divHeroConsumer {
        background-image: url('https://www.dellcdn.com/support/home/bundle/img/herosecauthlgmd.png');
        background-size:cover;
    }
    .herosectionauthenterprise, .herosectionauth {
        background-image: none !important;
    }*/
}