@media screen and (max-width: 767.9px) {
     .MostPopularSection {
         order: -1 !important;
     }
 }

@media screen and (min-width: 767.9px) {
     .tabsContainer {
         background-color: var(--gray-100);
     }
 }

/*.borderbottom {
    width: 653px;
}*/

.borderbottom:last-of-type {
    display: none !important;
}

.min-height-body {
    min-height: 400px;
    min-height: 55vh;
}

.rightarrowicon {
    text-decoration: none !important;
}

@media (max-width: 768px) {
     .TopicInterestTitle {
         font-weight: 400 !important;
     }
 }

.slider-font {
    font-family: Roboto !important;
    font-weight: 500 !important;
    letter-spacing: 0.012em !important;
}

.contact-sbutitle-font {
    font-size: 12px !important;
    line-height: 20px !important;
    letter-spacing: 0.005em !important;
}