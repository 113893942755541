@media (min-width: 1024px) {
     .dds__container .dds__col--lg,
     .dds__container-fluid .dds__col--lg-2_4 {
         flex: 0 0 auto;
         width: 20%;
     }
 }

.link_font-size {
    font-size: 14px !important;
}

@media (max-width: 1024px) {
    .optionsicons {
        position: relative;
        top: -2px;
    }
    .optionstilesheaders {
        font-size: 1.25rem !important;
        font-weight: 400 !important;   
    }
}